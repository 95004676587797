import React, { useEffect, useRef, useState } from "react";
import Loaders from "../Loaders";
import HeaderTop from "../HeaderTop";
import Menus from "../Menus";
import Footers from "../Footers";
import '../../../libs/css/bootstrap.min3.3.7.css';
import '../../../libs/css/sweetalert2.min.css';
import { API_ROUTES } from "../../../utils/constants";

import Nulls from "../Nulls";
import OnDemandsTable from "./OnDemandsTable";


export default function OnDemands(props){
    const {objectToFormData, errorFunction, Helmet, capitalizeWords, isAuthExpired } = props;

    useEffect(() => {document.title = "Subscription Services | Ringo"}, []);

    const [isLoading, setIsLoading] = useState(true);
    const [dataTotal, setDataTotal] = useState(0);
    const [dataList, setDataList] = useState([]);
    const [columns, setColumns] = useState([]);
    const [page, setPage] = useState(1);
    const countPerPage = 20;
    const [isFade, setIsFade] = useState(false);


    const columns1 = [
        {   
            name: 'USER',
            cell: row => row.user_name ? <div style={{ textAlign:'left' }}>{row.user_name}<div style={{ fontSize:'14px', color:'#069' }}>{row.user_email}</div></div> : <Nulls />,
            sortable: true,
        },
        {
            name: 'Service Name',
            selector: row => row.serviceName ? capitalizeWords(row.serviceName) : <Nulls />,
            sortable: true,
        },
        {   
            name: 'Network',
            cell: row => {
                let isNetwork = <div style={{ color:'orange' }}>MTN</div>
                if(row.network_id === 2){
                    isNetwork = <div style={{ color:'#72ae49' }}>GLO</div>
                }else if(row.network_id === 3){
                    isNetwork = <div style={{ color:'red' }}>AIRTEL</div>
                }else if(row.network_id === 4){
                    isNetwork = <div style={{ color:'#82AA40' }}>9MOBILE</div>
                }
                return (
                    <>
                        <b>{isNetwork}</b> &nbsp;<font style={{ fontSize:'14px' }}>({row.shortcode})</font>
                    </>
                )
            },
        },
        {
            name: 'Status',
            cell: row => {
                let isStatus = <div style={{ color:'orange' }}>Pending</div>
                if(row.status === 1){
                    isStatus = <div style={{ color:'red' }}>Failed</div>
                }else if(row.status === 2){
                    isStatus = <div style={{ color:'green' }}>Completed</div>
                }
                return isStatus;
            }
        },
        {
            name: 'Amount',
            selector: row => row.amount ? '₦'+parseFloat(row.amount).toLocaleString() : '0.00',
        },
        {   
            name: 'Product Name',
            selector: row => row.product_name ? capitalizeWords(row.product_name) : <Nulls />,
            sortable: true,
        },
    ];


    useEffect(() => {
        setColumns(columns1);
    }, []);

    const userData = JSON.parse(localStorage.getItem('userDataFs'));

    const getData = async (isLoad=true, serviceName="", keyword="", serviceId="", productName) => {
        let url = "";
        const dataString = { 
            page: page,
            // serviceName,
            // keyword,
            // serviceId,
            // product_name: productName,
        };
        setIsFade(true);

        try {
            const response = await fetch(API_ROUTES.ON_DEMAND_SERVICES, {
                method: "POST",
                body: objectToFormData(dataString),
                headers: {'token': userData?.responseDetails?.token},
            });

            const responseJSON = await response.json();
            isAuthExpired(responseJSON); // check for expired login

            if (responseJSON.status.toString() === "200") {
                const responseData = responseJSON.data;
                const totalData = responseData.length;

                // console.log(responseData);

                setDataTotal(totalData);
                setDataList(responseData);
                setIsLoading(false);
                setIsFade(false);
            }
        } catch (error) {
            console.log('Error getting data: ', error);
            setDataTotal({});
            setDataList([]);
            setIsLoading(false);
            setIsFade(false);
        }
    }

    
    useEffect(() => {
        getData(true, "", "", "");
    }, [page]);

    const handleChange1 = async (event) => {
        const filters = event.target.value;
        getData(true, "", filters, "");
    }

    const handleNtwk = async (event) => {
        const filters = event.target.value;
        getData(true, filters, "", "");
    }


    return (
        <>
            <Loaders />

            <div className="flapt-page-wrapper mainContent">
                <Menus />

                <div className="flapt-page-content">
                    <HeaderTop />

                    <div className="main-content introduction-farm mt--20">
                        <div className="content-wraper-area">
                            <div className="dashboard-area">
                                <div className="container-fluid mb-90">
                                    <div className="row g-4">
                                        <div className="col-lg-12 pl-xs-0 pr-xs-0">
                                            <div className="card-header mb--20 mb-xs-10">
                                                <h2>OnDemand Services</h2>
                                            </div>

                                            <OnDemandsTable capitalizeWords={capitalizeWords} dataList={dataList} dataTotal={dataTotal} isLoading={isLoading} columns={columns} countPerPage={countPerPage} setPage={setPage} handleChange1={handleChange1} isFade={isFade} handleNtwk={handleNtwk} />
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                        <Footers />
                    </div>
                </div>
            </div>
        </>
    );
}