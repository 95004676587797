import React, { useEffect, useState } from "react";
import Loaders from "./Loaders";
import HeaderTop from "./HeaderTop";
import Menus from "./Menus";
import Footers from "./Footers";
import { Helmet } from "react-helmet";
import { Link } from "react-router-dom";
import { API_ROUTES } from "../../utils/constants";
import { format } from "date-fns";
import PieChart from "./PieChart";
import moment from 'moment';




const Index = (props) => {
    const { capitalizeWords, objectToFormData, isAuthExpired } = props;
    const [isFade, setIsFade] = useState(true);
    const [todayRevLoading, setTodayRevLoading] = useState(true);
    
    const [serviceCounts, setServiceCounts] = useState(0);

    const [subscribersCounts, setSubscribersCounts] = useState(0);
    const [unSubscribersCounts, setUnSubscribersCounts] = useState(0);
    const [syncCounts, setSyncCounts] = useState(0);
    const [unSyncCounts, setUnSyncCounts] = useState(0);
    
    const [subscribersList, setSubscribersList] = useState([]);
    const [revenueLastMonth, setRevenueLastMonth] = useState(0);
    const [revenueLoading, setRevenueLoading] = useState(false);
    const [revenueThisMonth, setRevenueThisMonth] = useState(0);
    const [revenue1Loading, setRevenue1Loading] = useState(false);
    const [allTotals, setAllTotal] = useState(0);

    const [percentThisMonth, setPercentThisMonth] = useState(0);
    const [percentLastMonth, setPercentLastMonth] = useState(0);
    
    const [todayRev, setTodayRev] = useState(0);
    const [syncs, setSyncs] = useState({Telco_Sync_Count: 0, Partner_Sync_Count: 0});


    useEffect(() => {document.title = "Dashboard | Ringo"}, []);
    const userData = JSON.parse(localStorage.getItem('userDataFs'));

    const currentDate = new Date();
    const thisMonth = currentDate.toLocaleString('default', { month: 'long' });

    useEffect(() => {
        const loadScript = (src) => {
          return new Promise((resolve, reject) => {
            const script = document.createElement('script');
            script.src = src;
            script.async = true;
            script.onload = resolve;
            script.onerror = reject;
            document.body.appendChild(script);
          });
        };
        Promise.all([
        //   loadScript('../assets/libs_/js/jquery.min.js'),
          loadScript('../assets/libs_/js/scripts2.js'),
        ])
          .then(() => {
            // Both scripts are loaded
          })
          .catch((error) => {
            console.error('Error loading scripts:', error);
          });
        return () => {
          // Remove the scripts here if necessary
        };
    }, []);



    const getDataCounts = async () => {
        // const endDate = new Date();
        // const startDate = new Date();
        // startDate.setDate(startDate.getDate() - 30);
        const today = new Date();
        today.setHours(0, 0, 0, 0); // Set time to midnight
        
        const dataString = { 
            // fro: startDate.toLocaleDateString('en-US'),
            // to: endDate.toLocaleDateString('en-US'),
            fro: today.toLocaleDateString('en-US') + " 00:00:00",
            to: today.toLocaleDateString('en-US') + " 23:59:59",
        };
        // console.log("dataString ===");
        console.log(dataString);
        // return;

        try {
            const response = await fetch(API_ROUTES.ALL_COUNTS, {
                method: "POST",
                body: objectToFormData(dataString),
                headers: {'token': userData?.responseDetails?.token},
            });
            const responseJSON = await response.json();

            if (responseJSON.status.toString() === "200") {
                // const responseData = responseJSON.data;
                setSubscribersCounts(responseJSON.data.subscribers);
                setUnSubscribersCounts(responseJSON.data.unsubscribers);
                setSyncCounts(responseJSON.data.chargingSync);
                setUnSyncCounts(responseJSON.data.chargingUnsync);
            }
        } catch (error) {
            console.log('Error getting data: ', error);
            // setSubscribersList([]);
            setIsFade(false);
        }
    }

    const get_today_rev = async () => {
        setTodayRevLoading(true);
        try {
            const response = await fetch(API_ROUTES.TODAY_REVENUE, {
                method: "POST",
                headers: {'token': userData?.responseDetails?.token},
            });
            const responseJSON = await response.json();
            if (responseJSON.status.toString() === "200") {
                setTodayRevLoading(false);
                setTodayRev(responseJSON.data.totalRevenue);
            }
        } catch (error) {
            console.log('Error getting data: ', error);
            setTodayRevLoading(false);
        }
    }

    const getSyncs = async () => {
        const dataString = { 
            network: "MTN",
        };
        try {
            const response = await fetch(API_ROUTES.SYNCS, {
                method: "POST",
                body: objectToFormData(dataString),
                headers: {'token': userData?.responseDetails?.token},
            });
            const responseJSON = await response.json();
            if (responseJSON.status.toString() === "200") {
                setSyncs({Telco_Sync_Count: responseJSON.data.Telco_Sync_Count, Partner_Sync_Count: responseJSON.data.Partner_Sync_Count});
            }
        } catch (error) {
            console.log('Error getting data: ', error);
            setIsFade(false);
        }
    }



    useEffect(() => {
        getDataCounts();
        get_today_rev();
        // getSyncs();
    }, []);


    const getServiceCounts = async () => {
        const endDate = new Date();
        const startDate = new Date();
        startDate.setDate(startDate.getDate() - 30);
        
        const dataString = { 
            fro: startDate.toLocaleDateString('en-US'),
            to: endDate.toLocaleDateString('en-US'),
        };
        try {
            const response = await fetch(API_ROUTES.SERVICE_COUNTS, {
                method: "POST",
                body: objectToFormData(dataString),
                headers: {'token': userData?.responseDetails?.token},
            });
            const responseJSON = await response.json();
            if (responseJSON.status.toString() === "200") {
                setServiceCounts(responseJSON.data);
            }
        } catch (error) {
            console.log('Error getting data: ', error);
            setServiceCounts(0);
        }
    }
    useEffect(() => {
        getServiceCounts();
    }, []);



    const getSubscribers = async () => {
        const endDate = new Date();
        const startDate = new Date();
        startDate.setDate(startDate.getDate() - 7); // 7 days
        endDate.setDate(endDate.getDate() + 1);
        
        const dataString = { 
            token: userData?.responseDetails?.token,
            fro: startDate.toLocaleDateString('en-US'),
            to: endDate.toLocaleDateString('en-US'),
        };
        try {
            const response = await fetch(API_ROUTES.SUBSCRIBERS, {
                method: "POST",
                body: objectToFormData(dataString),
                headers: {'token': userData?.responseDetails?.token},
            });
            const responseJSON = await response.json();
            isAuthExpired(responseJSON); // check for expired login

            if (responseJSON.status.toString() === "200") {
                const responseData = responseJSON.data;

                setSubscribersList(responseData.slice(0, 6));
                setIsFade(false);
            }
        } catch (error) {
            console.log('Error getting data: ', error);
            setSubscribersList([]);
            setIsFade(false);
        }
    }
    useEffect(() => {
        getSubscribers();
    }, []);


    // const formatDate = (date) => {
    //     const year = date.getFullYear();
    //     const month = (date.getMonth() + 1).toString().padStart(2, '0');
    //     const day = date.getDate().toString().padStart(2, '0');
    //     return `${year}-${month}-${day}`;
    // }


    // const getThisMonthTotalRevenue = async () => {
    //     const currentDate = new Date();
    //     const firstDayOfMonth = new Date(currentDate.getFullYear(), currentDate.getMonth(), 1);
    //     const lastDayOfMonth = new Date(currentDate.getFullYear(), currentDate.getMonth() + 1, 0);
    //     const formattedBeginningOfMonth = formatDate(firstDayOfMonth);
    //     const formattedEndOfMonth = formatDate(lastDayOfMonth);
        
    //     const dataString = { 
    //         token: userData?.responseDetails?.token,
    //         fro: formattedBeginningOfMonth,
    //         to: formattedEndOfMonth,
    //     };
    //     setRevenue1Loading(true);
    //     try {
    //         const response = await fetch(API_ROUTES.TOTAL_REVENUE, {
    //             method: "POST",
    //             body: objectToFormData(dataString),
    //             headers: {'token': userData?.responseDetails?.token},
    //         });
    //         const responseJSON = await response.json();
    //         if (responseJSON.status.toString() === "200") {

    //             const totalRevenue = responseJSON.data.totalRevenue;
    //             const numericValue = parseFloat(totalRevenue.replace(/[^\d.]/g, ''));
    //             setRevenueThisMonth(numericValue);
    //             setRevenue1Loading(false);
    //         }
    //     } catch (error) {
    //         console.log('Error getting data: ', error);
    //         setRevenueThisMonth(0);
    //         setRevenue1Loading(false);
    //     }
    // }
    // useEffect(() => {
    //     getThisMonthTotalRevenue();
    // }, []);


    // function getLastMonthRange() {
    //     const firstDayLastMonth = moment().subtract(1, 'months').startOf('month');
    //     const lastDayLastMonth = moment().subtract(1, 'months').endOf('month');
    //     return {
    //         startOfLastMonth: firstDayLastMonth.format('YYYY-MM-DD 00:00:00'),
    //         endOfLastMonth: lastDayLastMonth.format('YYYY-MM-DD 23:59:59')
    //     };
    // }


    // const getLastMonthTotalRevenue = async () => {
    //     // const firstDayOfLastMonth = new Date(currentDate.getFullYear(), currentDate.getMonth() - 1, 1);
    //     // const lastDayOfLastMonth = new Date(currentDate.getFullYear(), currentDate.getMonth(), 0);
    //     // const formattedBeginningOfLastMonth = formatDate(firstDayOfLastMonth);
    //     // const formattedEndOfLastMonth = formatDate(lastDayOfLastMonth);

    //     const { startOfLastMonth, endOfLastMonth } = getLastMonthRange();
        
    //     const dataString = { 
    //         token: userData?.responseDetails?.token,
    //         fro: startOfLastMonth,
    //         to: endOfLastMonth,
    //     };
    //     setRevenueLoading(true);
    //     try {
    //         const response = await fetch(API_ROUTES.TOTAL_REVENUE, {
    //             method: "POST",
    //             body: objectToFormData(dataString),
    //             headers: {'token': userData?.responseDetails?.token},
    //         });
    //         const responseJSON = await response.json();
    //         if (responseJSON.status.toString() === "200") {
    //             const totalRevenue = responseJSON.data.totalRevenue;
    //             const numericValue = parseFloat(totalRevenue.replace(/[^\d.]/g, ''));
    //             setRevenueLastMonth(numericValue);
    //             setRevenueLoading(false);
    //         }
    //     } catch (error) {
    //         console.log('Error getting data: ', error);
    //         setRevenueLastMonth(0);
    //         setRevenueLoading(false);
    //     }
    // }

    // useEffect(() => {
    //     getLastMonthTotalRevenue();
    // }, []);


    useEffect(() => {
        const allTotal = parseFloat(revenueLastMonth) + parseFloat(revenueThisMonth);
        setAllTotal(allTotal);
        
        const lastMonthVal = parseFloat((parseFloat(revenueLastMonth) / parseFloat(allTotal))) * 100;
        const thisMonthVal = parseFloat(parseFloat(revenueThisMonth) / parseFloat(allTotal)) * 100;

        setPercentThisMonth(isNaN(thisMonthVal) ? 0 : thisMonthVal.toFixed(1));
        setPercentLastMonth(isNaN(lastMonthVal) ? 0 : lastMonthVal.toFixed(1));
    }, [revenueLastMonth, revenueThisMonth]);

    

    currentDate.setMonth(currentDate.getMonth() - 1);
    const lastMonth = currentDate.toLocaleString('default', { month: 'long' });


    return (
        <>
            <Loaders />

            <div className="flapt-page-wrapper mainContent">
                <Menus />

                <div className="flapt-page-content">
                    <HeaderTop />

                    <div className="main-content introduction-farm pl-15 pr-15 pl-xs-0 pr-xs-0">
                        <div className="content-wraper-area">
                            <div className="dashboard-area">
                                <div className="container-fluid">
                                    <div className="row g-4">
                                        <div className="col-12 mb-15">
                                            <div className="d-flex align-items-center justify-content-between">
                                                <div className="dashboard-header-title">
                                                    <h2 className="mb-0 mt--5">Your Dashboard</h2>
                                                </div>

                                                {/* <div className="dashboard-infor-mation">
                                                    <div className="dashboard-btn-group d-flex align-items-center">
                                                        <a href="#" className="dash-btn ms-2"><i className="ti-settings"></i></a>
                                                        <a href="#" className="dash-btn ms-2"><i className="ti-plus"></i></a>
                                                    </div>
                                                </div> */}
                                            </div>
                                        </div>

                                        
                                        <div className="col-sm-12 col-lg-5 col-xxl-5">
                                            <div className="card_height">
                                                <div className="card profile-greeting p-0">
                                                    <div className="card-body text-center">
                                                        <div className="img-overlay">
                                                            <h1 className="mt--5_">Hello, {capitalizeWords(userData?.responseDetails?.name)}</h1>
                                                            <p className="pt-10 mb-lg-50 mb-md-50 mb-xs-30" style={{opacity:'0.8'}}>Welcome to the Ringo Dashboard! <span className="d-sm-block d-none">We are happy to have you visit our dashboard.</span></p>

                                                            <div className="offset-lg-2 col-lg-8 offset-sm-2 col-sm-8">
                                                                <Link className="btn btn-block btn-primary btn-primary2 btn-primaryi" to="/settings">My Settings</Link>
                                                            </div>
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>


                                        <div className="col-sm-6 col-lg-3 col-xl-4 col-xxl-3 mt-md-30 mt-xs--10">
                                            <div className="card card_height mt-sm-15 mt-xs--15">
                                                <div className="card-body" data-intro="New Orders">
                                                    <div className="single-widget">
                                                        <div>
                                                            <div className="row wIcons">
                                                                <div className="col-md-4"><i className="bx bx-history"></i></div>
                                                                <div className="col-md-8 text-start" style={{marginTop:'-10px'}}>
                                                                    {/* <div style={{ position:'absolute', top:'6px' }}>{thisMonth}</div> */}
                                                                    <div style={{ position:'absolute', top:'6px' }}>Today</div>
                                                                    <h3 className="mt-25">Statistics</h3>
                                                                </div>
                                                            </div>

                                                            <div className="mt-15 mb-xs-15">
                                                                <div className="row mb-10">
                                                                    <div className="col-8">Subscription</div>
                                                                    <div className="col-4 text-end colored">{subscribersCounts !== null ? subscribersCounts.toLocaleString() : 0}</div>
                                                                </div>
                                                                <div className="row mb-10">
                                                                    <div className="col-8">UnSubscription</div>
                                                                    <div className="col-4 text-end colored">{unSubscribersCounts !== null ? unSubscribersCounts.toLocaleString() : 0}</div>
                                                                </div>
                                                                <div className="row mb-10">
                                                                    <div className="col-8">Services</div>
                                                                    <div className="col-4 text-end colored">{serviceCounts !== null ? serviceCounts.toLocaleString() : 0}</div>
                                                                </div>
                                                                
                                                                {/* <div className="row mb-10">
                                                                    <div className="col-8">Telco SYNC</div>
                                                                    <div className="col-4 text-end colored">{syncs.Telco_Sync_Count > 0 ? syncs.Telco_Sync_Count.toLocaleString() : <font style={{ color:'#888', fontSize: '13px', fontWeight:'400' }}>Loading...</font>}</div>
                                                                </div>
                                                                <div className="row">
                                                                    <div className="col-8">Partner SYNC</div>
                                                                    <div className="col-4 text-end colored">{syncs.Partner_Sync_Count > 0 ? syncs.Partner_Sync_Count.toLocaleString() : <font style={{ color:'#888', fontSize: '13px', fontWeight:'400' }}>Loading...</font>}</div>
                                                                </div> */}
                                                            </div>
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>

                                        <div className="col-sm-6 col-lg-4 col-xl-5 col-xxl-4 mt-md-30 mt-xs--10">
                                            <div className="card card_height mt-sm-15 mt-xs--15">
                                                <div className="card-body" data-intro="New Customers">
                                                    <div className="single-widget">
                                                        <div className="dashboard-dropdown">
                                                            <h3 className="mt-10">Analysis</h3>
                                                            <p>Analysis between last month and current month of the year</p>

                                                            <div className="row pl-5 mt-15" style={{ fontSize:'16px' }}>
                                                                <div className="col-4 pb-5">
                                                                    Today
                                                                </div>
                                                                <div className="col-8 pb-5">
                                                                    {
                                                                        todayRevLoading ?
                                                                        <font style={{ fontSize:'12px', color:'#666', fontWeight:'500' }}>Loading data...</font>
                                                                        :
                                                                        <>&#8358;{parseFloat(todayRev).toLocaleString()}</>
                                                                    }
                                                                    
                                                                </div>

                                                                {/* <div className="col-4 pb-5">
                                                                    {thisMonth}
                                                                </div>
                                                                <div className="col-8 pb-5">
                                                                    {
                                                                        revenue1Loading ?
                                                                        <font style={{ fontSize:'13px', color:'#666', fontWeight:'500' }}>Loading data...</font>
                                                                        :
                                                                        <>
                                                                            &#8358;{parseFloat(revenueThisMonth).toLocaleString()}
                                                                            &nbsp; <font style={{ fontSize:'14px', color:'#666', fontWeight:'500' }}>({percentThisMonth}%)</font>
                                                                        </>
                                                                    }
                                                                </div>
                                                                
                                                                <div className="col-4 pb-5">
                                                                    {lastMonth}
                                                                </div>
                                                                <div className="col-8 pb-5">
                                                                    {
                                                                        revenueLoading ? 
                                                                        <font style={{ fontSize:'13px', color:'#666', fontWeight:'500' }}>Loading data...</font>
                                                                        :
                                                                        <>
                                                                            ₦{parseFloat(revenueLastMonth).toLocaleString()}
                                                                            &nbsp;
                                                                            <font style={{ fontSize:'14px', color:'#666', fontWeight:'500' }}>({percentLastMonth}%)</font>
                                                                        </>
                                                                    }
                                                                    
                                                                    
                                                                </div> */}
                                                            </div>
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    </div>



                                    <div className="row mt-10 mt-sm-5 mt-xs-0">
                                        <div className="col-lg-12 col-md-12 mb-20 mb-sm-10">
                                            <div className="card">
                                                <div className="card-body pt-5">
                                                    <div className="card-title border-bootom-none mb-10 d-flex align-items-center justify-content-between">
                                                        <h6 className="mb-0">Last 6 Subscribers</h6>
                                                        <div className="dashboard-dropdown table_dropdown">
                                                            <div className="dropdown">
                                                                <button className="btn btn_dots dropdown-toggle_" type="button" id="dashboardDropdown57" data-bs-toggle="dropdown" aria-haspopup="true" aria-expanded="false"><i className="ti-more-alt"></i></button>
                                                                
                                                                <div className="dropdown-menu dropdown-menu-right" aria-labelledby="dashboardDropdown57">
                                                                    <Link className="dropdown-item" to="/subscribers"><i className="ti-eye"></i>View All</Link>
                                                                </div>
                                                            </div>
                                                        </div>
                                                    </div>
                                                    <div className="table-responsive text-nowrap">
                                                        <table className="table table-centered table-nowrap table-hover mb-0" style={{ opacity: isFade ? '0.4' : 1 }}>
                                                            <thead>
                                                                <tr>
                                                                    <th>Sender</th>
                                                                    <th>Receiver</th>
                                                                    <th>Message</th>
                                                                    <th>Service Name</th>
                                                                    <th>Date</th>
                                                                </tr>
                                                            </thead>
                                                            <tbody>
                                                                {
                                                                    subscribersList.length !== 0 && subscribersList.map(subscriber => {
                                                                        let subPhone = subscriber.senderAddress;

                                                                        if(subPhone.length >= 13){
                                                                            const countryCode = subPhone.slice(0, 3);
                                                                            const restOfNumber = subPhone.slice(3);
                                                                            subPhone = `${countryCode}-${restOfNumber}`;
                                                                        }
                                                                        return (
                                                                            <tr key={subscriber.id}>
                                                                                <td>{subPhone}</td>
                                                                                <td>{subscriber.receiverAddress}</td>
                                                                                <td>{subscriber.message}</td>
                                                                                <td>{capitalizeWords(subscriber.serviceName)}</td>
                                                                                <td>{subscriber.created_at ? format(new Date(subscriber.created_at), 'MMM d, yyyy h:mma') : 'Not Specified'}</td>
                                                                            </tr>
                                                                        );
                                                                    })
                                                                }

                                                                {
                                                                    subscribersList.length === 0 && 
                                                                    <tr style={{ textAlign:'center' }}>
                                                                        <td style={{ fontSize:'17px' }} colSpan="5" className="pt-20 pb-20">No subscribers found for the last 7 days</td>
                                                                    </tr>
                                                                }
                                                            </tbody>
                                                        </table>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>

                                        {/* <div className="col-lg-4 mb-20">
                                            <div className="card mt-xs--15">
                                                <div className="card-body pt-5">
                                                    <div className="card-title mb-30 d-flex align-items-center justify-content-between">
                                                        <h6 className="mb-0">Analysis</h6>
                                                        <div className="dashboard-dropdown">
                                                            <div className="dropdown">
                                                                <button className="btn btn_dots dropdown-toggle_" type="button" id="dashboardDropdown60" data-bs-toggle="dropdown" aria-haspopup="true" aria-expanded="false"><i className="ti-more-alt"></i></button>

                                                                <div className="dropdown-menu dropdown-menu-right" aria-labelledby="dashboardDropdown57">
                                                                    <Link className="dropdown-item" to="/"><i className="ti-eye"></i>View All</Link>
                                                                </div>
                                                            </div>
                                                        </div>
                                                    </div>

                                                    <div className="chart-area">
                                                        <div id="pie-chart"></div>
                                                    </div>
                                                </div>
                                            </div>
                                        </div> */}
                                    </div>
                                </div>
                            </div>
                        </div>


                        <Footers />
                    </div>
                </div>
            </div>
        </>
    );
}

export default Index;