import React, { useState } from "react";
import { Link, useLocation, useNavigate } from "react-router-dom";

export default function Menus(){
    const navigate = useNavigate();

    const logout = () => {
        localStorage.removeItem('userDataFs');
        navigate('/login');
    }

    const [isMenuOpen, setIsMenuOpen] = useState(false);
    const location = useLocation();

    const toggleMenu = () => {
        setIsMenuOpen(!isMenuOpen);
    };

    const [isLogsMenuOpen, setIsLogsMenuOpen] = useState(false);
    const [isSubMenuOpen, setIsSubMenuOpen] = useState(false);
    const [isSettingsMenuOpen, setIsSettingsMenuOpen] = useState(false);
    const [isDemandMenuOpen, setIsDemandMenuOpen] = useState(false);

    const toggleLogsMenu = () => {
        setIsLogsMenuOpen(!isLogsMenuOpen);
    };
    const toggleSubMenu = () => {
        setIsSubMenuOpen(!isSubMenuOpen);
    };

    const toggleDemandMenu = () => {
        setIsDemandMenuOpen(!isDemandMenuOpen);
    };

    

    const toggleSettingsMenu = () => {
        setIsSettingsMenuOpen(!isSettingsMenuOpen);
    };

    const excludedPaths = ['/dashboard', '/settings'];

    return (
        <div className="flapt-sidemenu-wrapper">
            <div className="flapt-logo" style={{ textAlign:'center' }}>
                <Link to="/dashboard" className="text-center" style={{ textAlign:'center' }}>
                    {/* <img className="desktop-logo" src="assets/images/logo2.png" alt="Ringo Logo" /> <img className="small-logo" src="assets/images/small-logo.png" alt="Ringo Logo" /> */}

                    <label style={{ fontSize:40, color: '#f54aab' }}>Rin<label style={{ color:'#a63977' }}>go</label></label>
                </Link>
            </div>

            <div className="flapt-sidenav" id="flaptSideNav">
                <div className={`side-menu-area ${(!excludedPaths.includes(location.pathname)) ? 'mt-100' : 'mt-30'}`}>
                {/* <div className={`side-menu-area mt-30`}> */}
                    <nav>
                        <ul className="sidebar-menu" data-widget="tree">
                            {/* <li className="menu-header-title">Dashboard</li> */}
                            
                            <li className={location.pathname === '/dashboard' ? 'active' : ''}>
                                <Link to="/dashboard">
                                    <i className="bx bx-home"></i>
                                    <span>Dashboard</span>
                                </Link>
                            </li>

                            <li className={location.pathname === '/service-list' ? 'active' : ''}>
                                <Link to="/service-list">
                                    <i className="bx bx-list-plus"></i>
                                    <span>Service List</span>
                                </Link>
                            </li>

                            <li className={location.pathname === '/revenue-report' ? 'active' : ''}>
                                <Link to="/revenue-report">
                                    <i className="bx bx-list-plus"></i>
                                    <span>Revenue Report</span>
                                </Link>
                            </li>

                            {/* <li className={location.pathname === '/products' ? 'active' : ''}>
                                <Link to="/products">
                                    <i className="bx bxs-cart"></i>
                                    <span>Products</span>
                                </Link>
                            </li> */}

                            <li className={`treeview ${location.pathname === '/charging-logs' || location.pathname === '/charging-unsync-logs' ? 'active' : ''}`}>
                                <a href="#" onClick={toggleLogsMenu}>
                                    <i className="ti ti-plug"></i>
                                    <span>View Logs</span>
                                    <i className={`fa fa-angle-right ${isLogsMenuOpen ? 'rotate-icon' : ''}`}></i>
                                </a>
                                <ul className="treeview-menu" style={{ display: isLogsMenuOpen ? 'block' : 'none' }}>
                                    <li><Link to="/charging-logs">Charging SYNC</Link></li>
                                    <li><Link to="/charging-unsync-logs">Charging UNSYNC</Link></li>
                                </ul>
                            </li>

                            <li className={`treeview ${location.pathname === '/subscribers' || location.pathname === '/unsubscribers' ? 'active' : ''}`}>
                                <a href="#" onClick={toggleSubMenu}>
                                    <i className="ti ti-rocket"></i>
                                    <span>Subscribers</span>
                                    <i className={`fa fa-angle-right ${isSubMenuOpen ? 'rotate-icon' : ''}`}></i>
                                </a>
                                <ul className="treeview-menu" style={{ display: isSubMenuOpen ? 'block' : 'none' }}>
                                    <li><Link to="/subscribers">Subscribers</Link></li>
                                    <li><Link to="/unsubscribers">UnSubscribers</Link></li>
                                </ul>
                            </li>

                            <li className={`treeview ${location.pathname === '/demands' || location.pathname === '/on-demands' ? 'active' : ''}`}>
                                <a href="#" onClick={toggleDemandMenu}>
                                    <i className="ti ti-medall"></i>
                                    <span>Subscription Service</span>
                                    <i className={`fa fa-angle-right ${isDemandMenuOpen ? 'rotate-icon' : ''}`}></i>
                                </a>
                                <ul className="treeview-menu" style={{ display: isDemandMenuOpen ? 'block' : 'none' }}>
                                    <li><Link to="/demands">Subscription</Link></li>
                                    <li><Link to="/on-demands">OnDemand</Link></li>
                                </ul>
                            </li>

                            <li className={location.pathname === '/settings' ? 'active' : ''}>
                                <Link to="/settings">
                                    <i className="ti ti-settings"></i>
                                    <span>Settings</span>
                                </Link>
                            </li>
                            
                            <hr />

                            {/* <li className={`treeview ${location.pathname} === '/dashboard' ? 'active' : ''`}> */}
                            <li>
                                <Link onClick={logout} to="/">
                                <i className="ti ti-power-off"></i>
                                <span>Logout</span>
                                </Link>
                            </li>
                            {/* </li> */}
                        </ul>
                    </nav>
                </div>
            </div>
        </div>

    );
}